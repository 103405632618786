:root {
  --color-primary: #0737a0;
  --color-secondary: #05d24b;
  --color-link: #0d66db;
  --color-danger: #e53009;
  --color-warning: #f0ad4e;
  --white: #fff;
  --text-primary: #323232;
  --text-secondary: #8c8c8c;
  --grey1: #3a3b3c;
  --grey2: #828282;
  --grey3: #eff1f3;
}

html {
  --text-color: rgb(17, 24, 39);
  --bg-color: #fff;
}
html[data-theme="dark"] {
  --bg-color: #283547;
  --text-color: #fff;
}

/* @font-face {
  font-family: "Erply-Font-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("https://assets.erply.com/back-office/back-office-menu/Erply-Ladna/OTF/ErplyLadna-Regular.otf");
} */

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: "Erply-Ladna";
}

html {
  box-sizing: border-box;
  /* font-size: 62.5%; */
}

body {
  font-family: "Erply-Ladna";
  /* font-size: 1.6rem; */
  background-color: hsl(0, 0%, 98%);
  color: var(--text-primary);
  font-weight: 96;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

button:focus {
  outline: 0;
}

/* input [type="checkbox"]:checked {
  color: red !important;
} */

.logo {
  position: absolute;
  left: 2.14%;
  right: 92.5%;
  top: 4%;
  bottom: 94%;
  z-index: 10;
  /* transform: matrix(1, 0, 0, -1, 0, 0); */
}

.main {
  display: flex;
  align-items: center;
  width: 100vw;
}

.side-layout {
  /* Rectangle 101 */

  width: 557px;
  height: 100vh;

  /* BlocksHover */

  background: var(--grey3);
}

.side-layout__image {
  /* position: absolute;
  width: inherit; */
  /* height: 620px; */
  left: 55px;
  top: 150px;

  /* background-image: url("./assets/Workspace_2_PNG\ 1.png"); */
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
}

.container {
  max-width: 114rem;
  margin: 0 auto;
}

.d-flex {
  display: flex;
  align-items: center;
}

/* .btn {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  border: 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  color: var(--white);
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-block {
  width: 100% !important;
}

.btn-md {
  width: 40%;
}

.btn-primary {
  background-color: var(--color-primary);
}

.btn-primary:hover {
  background-color: var(--white);
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.btn-secondary {
  background-color: var(--color-secondary);
}

.btn-secondary:hover {
  background-color: var(--white);
  color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
}

.btn-link {
  background-color: var(--color-link);
}

.btn-link:hover {
  background-color: var(--white);
  color: var(--color-link);
  border: 1px solid var(--color-link);
}

.btn-normal {
  background-color: var(--white);
  color: var(--text-secondary);
  border: 1px solid var(--grey3);
}

.btn-normal:hover {
  background-color: var(--text-secondary);
  color: var(--white);
  border: 0;
}

.btn-warning {
  background-color: var(--color-warning);
  color: var(--text-primary);
  border: 0;
} */

/* @media only screen and (max-width: 1200px) {
  .container {
    padding: 0 3rem;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    max-width: 60rem;
  }
} */

@media only screen and (max-width: 768px) {
  .main {
    /* flex-direction: column;
    align-items: stretch;
    justify-content: center; */
    display: block;
    width: 100%;
    /* overflow-x: hidden; */
  }

  .side-layout {
    /* position: relative;
    width: 100%;
    left: 0;
    right: 0;
    top: 0; */
    height: 200px;

    width: auto;
  }

  .side-layout__image img {
    width: 100px;
    height: auto;
  }

  /* .side-layout__image {
    top: 50px;
    left: 130px;
  } */
  .side-layout__image {
    /* top: 50px;
    left: -10px; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .lottie-a {
    width: 100px !important;
    height: auto !important;
    margin: auto;
  }
}

/* hr {
  border-color: rgba(255, 255, 255, 0.1) !important;
} */

.app-light {
  background-image: url("../assets/bg_l.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center; */
}

.app-dark {
  background-image: url("../assets/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.select-new {
  /* color: #fff !important;
  background-image: url("../assets/dropdown.svg") !important; */
  background-size: auto !important;
}

/* .input {
  border-color: #4b5d74;
} */

.input:focus {
  /* outline: 2px solid #3d94f6 !important;
  outline-offset: unset !important;
  border: 0; */
  outline: none !important;
  outline-offset: unset !important;
  border-color: #3d94f6 !important;
  border: 2px solid #3d94f6 !important;
}

.btn-styled {
  height: 44px !important;
  min-height: 44px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--bg-color) inset !important;
  -webkit-text-fill-color: var(--text-color) !important;
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
} */

.app-select {
  @apply dark:bg-erply-dark-500 bg-erply-alert-50 rounded-md flex items-center justify-between dark:text-white text-erply-alert-100 cursor-pointer lg:w-[600px] w-[335px] md:w-[450px] hover:text-white hover:border-none;
}

/* .app-select:hover{

} */

.app-select:hover h1 {
  @apply text-white;
}

.app-select:hover small {
  @apply text-white;
}

.back-office {
  @apply hover:bg-erply-success-200;
}

.back-office:hover button {
  @apply bg-white text-erply-success-200;
}

.point-of-sale {
  @apply hover:bg-erply-light-200;
}

.point-of-sale:hover button {
  @apply bg-white text-erply-light-200;
}

.service-app {
  @apply hover:bg-erply-brand-purple-100;
}

.service-app:hover button {
  @apply bg-white text-erply-brand-purple-100;
}

.retry-crm {
  @apply hover:bg-emerald-400;
}

.retry-crm:hover button {
  @apply bg-white text-emerald-400;
}

.btn-drop {
  height: 2rem !important;
  min-height: 2rem !important;
}
