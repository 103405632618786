/* light */
@font-face {
  font-family: "Erply-Ladna";
  src: url(https://assets.erply.com/global/fonts/erply-ladna/WOFF/ErplyLadna-Light.woff)
    format("woff");
  font-display: swap;
  font-weight: 70;
}
/* regular */
@font-face {
  font-family: "Erply-Ladna";
  src: url(https://assets.erply.com/global/fonts/erply-ladna/WOFF/ErplyLadna-Regular.woff)
    format("woff");
  font-display: swap;
  font-weight: 96;
}
/* medium */
@font-face {
  font-family: "Erply-Ladna";
  src: url(https://assets.erply.com/global/fonts/erply-ladna/WOFF/ErplyLadna-Medium.woff)
    format("woff");
  font-display: swap;
  font-weight: 115;
}
/* bold */
@font-face {
  font-family: "Erply-Ladna";
  src: url(https://assets.erply.com/global/fonts/erply-ladna/WOFF/ErplyLadna-Bold.woff)
    format("woff");
  font-display: swap;
  font-weight: 140;
}

/* icons */
@font-face {
  font-family: "Erply-Icons";
  src: url(https://assets.erply.com/global/fonts/erply-iconfont/erply-iconfont.woff)
    format("woff");
}
