/* Hide elements, for screen readers only */
.screen-reader {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.underline {
  width: 10rem;
  height: 0.25rem;
  margin-bottom: 1.25rem;
  background: var(--color-primary);
  margin-left: auto;
  margin-right: auto;
}

/* Reset lists style */
.list-reset {
  list-style: none;
  padding: 0;
}

.list-reset li {
  margin: 0;
}

/* Text alignment */
.ta-l {
  text-align: left;
}

.ta-c {
  text-align: center;
}

.ta-r {
  text-align: right;
}

/* Font weight */
.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

/* For centering content */
.center-content {
  text-align: center;
}

.center-content img,
svg,
video {
  margin-left: auto;
  margin-right: auto;
}

/* Spacing, spacers */
.spacer-4 {
  padding-top: 4px;
}

.spacer-8 {
  padding-top: 8px;
}

.spacer-12 {
  padding-top: 12px;
}

.spacer-16 {
  padding-top: 16px;
}

.spacer-24 {
  padding-top: 24px;
}

.spacer-32 {
  padding-top: 32px;
}

.spacer-48 {
  padding-top: 48px;
}

.spacer-64 {
  padding-top: 64px;
}

.text-color-error {
  color: var(--color-danger);
}

.text-color-warning {
  color: var(--color-warning);
}

.text-color-success {
  color: var(--color-secondary);
}

.text-color-info {
  color: var(--color-link);
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  @apply dark:bg-erply-light-10 bg-erply-dark-300;
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
