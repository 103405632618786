.selector-conatiner {
  margin-top: 15rem;
}

.app-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.app-content {
  height: 150px;
  width: 150px;
  background-color: #555;
  color: var(--white);
  border-radius: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: large;
  transition: all 0.3s;
}

/* .app-content:hover {
  transform: scale(1.03);
} */

/* .back-office {
  background-color: #00d1b2;
} */

.pos {
  background-color: #2196f3;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
