.form-container {
  width: 400px;
  height: 100vh;
  padding-top: 14rem;
  margin-left: auto;
  margin-right: auto;
}

.input-wrapper {
  max-width: 450px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/* .form-container span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;

  letter-spacing: -0.41px;

  color: #727981;
} */

.form-heading {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.3499999940395355px;
  text-align: left;
}

.login-options {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  cursor: pointer;
}

.option-username {
  height: 40px;
  min-width: 91px;
  border: 1px solid var(--grey2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.5rem;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #1c2126;
  opacity: 0.5;
  letter-spacing: 0.35px;
}

.active {
  border: 1px solid var(--color-primary) !important;
  color: var(--color-primary) !important;
  opacity: 1 !important;
}

.option-SSO {
  height: 40px;
  min-width: 91px;
  border: 1px solid var(--grey2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.5rem;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #1c2126;
  opacity: 0.5;
  letter-spacing: 0.35px;
}

.input-conatner {
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: 1px solid #eff1f3;
}

.input-conatner:focus-within {
  border: 1px solid var(--text-secondary);
}

.input-btn {
  /* position: absolute; */
  border: none;
  background: var(--white);
  /* right: 27rem; */
  cursor: pointer;
  margin-right: 0.9rem;
  /* margin-left: 1.75rem; */
}

.input-btn:focus {
  outline: none;
}

.input-btn:active {
  transform: translateY(2px);
}

.form-input {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: var(--white);
  border: none;
  padding: 1.2rem 1.5rem;
  flex-grow: 2;
  /* margin-right: -2.35rem; */
}

.form-input:focus {
  /* border: 1px solid var(--text-secondary); */
  outline: none;
}

.form-input:focus::-webkit-input-placeholder {
  color: #eff1f3;
}

.form-input::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: -0.24px;

  color: #727981;
}

.form-label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;

  letter-spacing: -0.08px;

  color: var(--text-secondary);
}

.form-hint {
  margin-top: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
}

.input-icon {
  height: 14.67px;
  width: 14.67px;
  color: #727981;
}

.forgot-password {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: -0.24px;

  color: var(--color-primary) !important;
  cursor: pointer;
}

.login-btn {
  width: 100%;
  padding: 1.2rem 1.5rem;
  border: 0;
  color: var(--white);
  cursor: pointer;
  background-color: var(--color-secondary);
}

.login-btn:hover {
  background-color: var(--white);
  color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
}

@media only screen and (max-width: 567px) {
  .form-container {
    /* margin: 0; */
    max-width: 400px;
    padding: 5rem;
    /* padding-right: 5rem; */
  }

  .form-input {
    padding: 0.7rem 1.5rem;
  }
  .login-btn {
    padding: 0.7rem 1.5rem;
  }
}
